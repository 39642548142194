<div class="d-flex flex-column b-light text-center p-2">
  <small *ngIf="containerData?.products?.length > 0" class="text-primary"
    >Drag products to rearrange them in sequence.</small
  >
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div
      class="mx-2 my-2 rectangle d-flex align-items-center justify-content-between"
      *ngFor="let item of containerData?.products"
      cdkDrag
    >
      <div class="mx-2">
        <i class="mr-2 material-icon">drag_indicator</i> <b>{{ item.id }}</b>
      </div>
      <div
        style="height: 10px; width: 40px"
        [ngStyle]="{ 'background-color': item.color }"
      ></div>
    </div>
  </div>

  <hr />

  <small *ngIf="excludedProducts?.length > 0" class="text-danger"
    >Excluded Products due to size constraint</small
  >
  <div
    class="mx-2 my-2 rectangle d-flex align-items-center justify-content-between"
    *ngFor="let item of excludedProducts"
  >
    <div class="mx-2">
      <b>{{ item.id }}</b>
    </div>
    <div
      style="height: 10px; width: 40px"
      [ngStyle]="{ 'background-color': item.color }"
    ></div>
  </div>

  <!-- <button
    (click)="viewStackingVideo()"
    class="btn mt-2 btn-sm bg-primary text-white btn-primary mx-2 my-1"
  >
    Visualise
  </button> -->
</div>

<div class="visualization-container"></div>
