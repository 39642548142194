<div style="height: 100%" class="card text-left b-light">
  <div class="img p-1 text-center" style="height: 200px">
    <swiper class="swiper-img" [pagination]="true" navigation="true">
      <ng-template
        swiperSlide
        *ngFor="
          let url of product?.productDetails?.productImageFileNames;
          let j = index
        "
      >
        <img
          class="cursor-zoom"
          (click)="
            openImageSlider(product?.productDetails?.productImageFileNames, j)
          "
          [src]="imgUrl + url"
          height="200px"
          width="100%"
          alt=""
        />
      </ng-template>
    </swiper>
  </div>
  <div style="height: 50px" class="d-flex align-items-center px-2 mt-2">
    <div class="w-100">
      <div
        style="text-wrap: nowrap; overflow: hidden; text-overflow: ellipsis"
        class="font-size-14 description"
        [matTooltip]="product?.productDetails?.description"
      >
        ({{ product?.productDetails?.productCode }})
        {{ product?.productDetails?.description }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isNoGenericPurchase(product) && !product?.isCustomized">
    <div class="mt-2" style="min-height: 70px">
      <table
        *ngIf="['SKU', 'UNIT']?.includes(buyingType?.value)"
        class="text-center quantity-table b-light"
      >
        <tr class="primary-text bg-light">
          <td>
            <b>   <b>{{ product?.productDetails?.skuPackageType}}</b></b>
          </td>
          <td
            class="text-muted cursor"
            (click)="
              setQuantity(
                product?.productDetails?.skuFirstMinimumQuantity,
                this.i
              )
            "
          >
            {{ product?.productDetails?.skuFirstMinimumQuantity }}-{{
              product?.productDetails?.skuFirstMaximumQuantity
            }}
          </td>
          <td
            class="text-muted cursor"
            (click)="
              setQuantity(
                product?.productDetails?.skuSecondMinimumQuantity,
                this.i
              )
            "
          >
            {{ product?.productDetails?.skuSecondMinimumQuantity }}-{{
              product?.productDetails?.skuSecondMaximumQuantity
            }}
          </td>
          <td
            class="text-muted cursor"
            (click)="
              setQuantity(
                product?.productDetails?.skuThirdMinimumQuantity,
                this.i
              )
            "
          >
            {{ product?.productDetails?.skuThirdMinimumQuantity }}+
          </td>
        </tr>
        <tr>
          <td class="primary-text">
            <b>
              {{ product?.productDetails?.skuSecondTier?.userConversionUom }}
            </b>
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.skuFirstTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.skuSecondTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.skuThirdTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
        </tr>
      </table>

      <table
        *ngIf="['PALLET']?.includes(buyingType?.value)"
        class="text-center quantity-table b-light"
      >
        <tr class="primary-text bg-light">
          <td>
            <b>{{ product?.productDetails?.unitName ?? "UNIT" }}</b>
          </td>
          <td
            class="text-muted cursor"
            (click)="
              setQuantity(
                product?.productDetails?.skuThirdMinimumQuantity,
                this.i
              )
            "
          >
            {{ product?.productDetails?.skuThirdMinimumQuantity }}+
          </td>
          <td class="text-muted cursor">
            Pallet ( {{ product?.productDetails?.numberInPallet }}
            {{ product?.productDetails?.skuPackageType }} )
          </td>
        </tr>
        <tr>
          <td class="primary-text">
            <b>{{ product?.productDetails?.palletTier?.userConversionUom }}</b>
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.skuThirdTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.palletTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
        </tr>
      </table>
      <table
        *ngIf="
          [
            'CONTAINER_40_FT',
            'CONTAINER_20_FT',
            'CONTAINER_40_FT_HQ'
          ]?.includes(buyingType?.value)
        "
        class="text-center quantity-table b-light"
      >
        <tr class="primary-text bg-light">
          <td>
            <b> {{ product?.productDetails?.unitName ?? "UNIT" }} </b>
          </td>
          <td class="text-muted cursor">CONTAINER 20FT</td>
          <td class="text-muted cursor">CONTAINER 40FT</td>
        </tr>
        <tr>
          <td class="primary-text">
            <b>
              {{ product?.productDetails?.containerTier?.userConversionUom }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.containerTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.containerTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
        </tr>
      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="isNoGenericPurchase(product) || product?.isCustomized">
    <div class="primary-text mt-2" style="min-height: 70px">
      <!-- This product is tailored specifically for customization. -->

      <div
        *ngIf="getTierPricingByProduct(product?.id)?.length > 0"
        class="b-light"
      >
        <table class="text-center quantity-table">
          <tr class="primary-text bg-light">
            <td style="width: 70px">
              <b> {{ product?.productDetails?.unitName ?? "UNIT" }} </b>
            
            </td>
            <ng-container
              *ngFor="
                let tierPricing of getTierPricingByProduct(product?.id)
                  | sortNumberProperty : 'minimumQuantity';
                let k = index
              "
            >
              <td
                [ngClass]="{
                  'bg-secondary':
                    tierPricing?.minimumQuantity == this.product.skuQuantities
                }"
                (click)="setQuantity(tierPricing.minimumQuantity, this.i)"
                *ngIf="k < 3"
                class="cursor text-muted"
              >
                {{ tierPricing.minimumQuantity }}
              </td>
            </ng-container>

            <td class="text-muted">ORDER DELIVERY</td>
          </tr>
          <tr
            class="b-bottom-secondary"
            *ngFor="
              let deliveryPricing of getTierPricingByProduct(product?.id)[0]
                ?.deliveryPricing;
              let m = index
            "
          >
            <td class="text-primary" rowspan="2" *ngIf="m == 0">
              <b> {{ deliveryPricing?.deliveryCost?.userConversionUom }} </b>
            </td>
            <ng-container
              *ngFor="
                let tierPricing of getTierPricingByProduct(product?.id)
                  | sortNumberProperty : 'minimumQuantity';
                let k = index
              "
            >
              <td
                (click)="
                  setDays(
                    deliveryPricing?.numberOfDays,
                    tierPricing?.minimumQuantity,
                    this.i
                  )
                "
                *ngIf="k < 3"
                class="width-100"
                [matTooltip]="
                  isSelfProduct(product?.productDetails)
                    ? 'Please Request Vendor For Pricing'
                    : ''
                "
                [ngClass]="{
                  'bg-secondary':
                    tierPricing?.minimumQuantity ==
                      this.product.skuQuantities &&
                    deliveryPricing?.numberOfDays == this.product.deliveryDays
                }"
              >
                {{
                  isSelfProduct(product?.productDetails)
                    ? "-"
                    : (tierPricing?.deliveryPricing[m]?.deliveryCost
                        ?.attributeValue | numberFormatter : 3)
                }}
              </td>
            </ng-container>

            <td
              (click)="setDays(deliveryPricing?.numberOfDays, null, this.i)"
              [ngClass]="{
                'bg-secondary':
                  deliveryPricing?.numberOfDays == this.product.deliveryDays
              }"
              class="width-100"
            >
              {{
                purchaseOrderService.daysFreightMap[
                  deliveryPricing?.numberOfDays
                ]
              }}
              ({{ deliveryPricing?.numberOfDays }} days)
            </td>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>

  <div class="d-flex align-items-center justify-content-between px-2">
    <div class="d-flex align-items-center justify-content-start">
      <div style="text-wrap: nowrap">
        <ng-container *ngIf="['SKU', 'UNIT']?.includes(buyingType?.value)">
          <b
            *ngIf="product.productDetails?.skuCost?.attributeValue"
            class="fw-bold font-size-14 primary-text"
          >
            {{
              isSelfProduct(product?.productDetails)
                ? "-"
                : product?.skuCost?.userConversionUom +
                  " " +
                  (product?.skuCost?.attributeValue | numberFormatter : 3) +
                  " "
            }}
          </b>
        </ng-container>
        <ng-container *ngIf="['PALLET']?.includes(buyingType?.value)">
          <b
            *ngIf="product.productDetails?.palletTier?.attributeValue"
            class="fw-bold font-size-14 primary-text"
          >
            {{
              isSelfProduct(product?.productDetails)
                ? "-"
                : product.productDetails?.palletTier?.userConversionUom +
                  " " +
                  (product.productDetails?.palletTier?.attributeValue
                    | numberFormatter : 3) +
                  " "
            }}
          </b>
        </ng-container>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div [ngbTooltip]="customtooltip">
        <span
          class="d-flex align-items-center justify-content-center mx-1"
          [isDisabled]="hideAddToOrder(product?.productDetails?.audit)"
          [matTooltip]="
            hideAddToOrder(product?.productDetails?.audit)
              ? 'To add this Product, select this vendor from above'
              : null
          "
          label="View Detail"
          [theme]="'primary'"
          [ngClass]="{
            'self-product': getOwner(product?.productDetails?.audit) == 'S',
            'third-product': getOwner(product?.productDetails?.audit) == 'T',
            'master-product': getOwner(product?.productDetails?.audit) == 'M'
          }"
        >
          {{ getOwner(product?.productDetails?.audit) }}
        </span>
      </div>
      <div style="height: 24px">
        <mat-icon style="height: 24px" class="star-icon-size primary-text"
          >star</mat-icon
        >
      </div>
      <div class="font-size-14">
        <b class="text-primary">
          {{ getRating(product?.productDetails?.productCode) }}
        </b>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between px-2">
    <div class="d-flex align-items-center text-muted">
      <small>
        <ng-container
          *ngIf="product?.isCustomized || isNoGenericPurchase(product)"
        >
          {{ product?.unitQuantities }}
          {{ product?.productDetails?.unitName ?? "UNIT" }} (
          {{ purchaseOrderService.daysFreightMap[product?.deliveryDays] }}
          {{ product?.deliveryDays }} Days)
        </ng-container>
        <ng-container
          *ngIf="!product?.isCustomized && !isNoGenericPurchase(product)"
        >
          {{ product?.productDetails?.numberOfItems }}
          {{ product?.productDetails?.unitName ?? "UNIT" }}/{{
            product?.productDetails?.skuPackageType
          }}
        </ng-container>
      </small>
    </div>
    <div class="d-flex align-items-center text-muted text-right b-top-primary">
      {{ 200 + (i + 7) * 13 }}
    </div>
  </div>

  <div style="height: 60px" class="b-light bg-light primary-text mt-2 mx-2">
    <div class="row mx-auto" style="text-wrap: nowrap">
      <div class="col-6 font-size-10">
        <b> {{ product?.productDetails?.unitName ?? "UNIT" }}</b>
        <div class="d-flex bg-white my-1">
          <dadyin-button
            [theme]="'borderless-primary'"
            type="image"
            [typeval]="'assets/img/icons/Minus.svg'"
            (clicked)="minus(this.i, product.quantity)"
          ></dadyin-button>
          <input
            type="number"
            min="1"
            (change)="changeQuantity($event, i)"
            required
            class="text-center w-100 b-none"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="product.quantity"
          />
          <dadyin-button
            [theme]="'borderless-primary'"
            type="image"
            [typeval]="'assets/img/icons/added.svg'"
            (clicked)="plus(this.i, product.quantity)"
          ></dadyin-button>
        </div>
      </div>
      <div class="col-6 font-size-10 text-right">
        <b>Order Value</b><br />
        <div class="my-2">
          <div
            *ngIf="product?.totalCost?.attributeValue"
            class="price-text font-size-14"
            [matTooltip]="
              isSelfProduct(product?.productDetails)
                ? 'Please Request Vendor For Pricing'
                : ''
            "
          >
            {{
              isSelfProduct(product?.productDetails)
                ? "-"
                : product?.totalCost?.userConversionUom +
                  " " +
                  (product?.totalCost?.attributeValue | numberFormatter : 3)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="height: 50px; column-gap: 5px"
    class="d-flex align-items-center justify-content-around p-2"
  >
    <dadyin-button
      class="w-100"
      [isDisabled]="hideAddToOrder(product?.productDetails?.audit)"
      (clicked)="viewDetail(product, this.i, product.isCustomized)"
      [label]="isNoGenericPurchase(product) ? 'More Tiers' : 'View Details'"
      [theme]="isNoGenericPurchase(product) ? 'secondary' : 'secondary'"
      [matTooltip]="
        hideAddToOrder(product?.productDetails?.audit)
          ? 'To add this Product, select this vendor from above'
          : null
      "
    >
    </dadyin-button>
    <dadyin-button
      class="w-100"
      style="text-wrap: nowrap"
      (clicked)="addProductToOrder(product)"
      [isDisabled]="hideAddToOrder(product?.productDetails?.audit)"
      [matTooltip]="
        hideAddToOrder(product?.productDetails?.audit)
          ? 'To add this Product, select this vendor from above'
          : null
      "
      [label]="showHideButtonLabel(product) ? 'Update Order' : 'Add to Order'"
      [theme]="showHideButtonLabel(product) ? 'secondary' : 'primary'"
    >
    </dadyin-button>

    <dadyin-button
      matTooltip="Remove from cart"
      *ngIf="showHideButtonLabel(product)"
      (clicked)="deleteProductFromOrder(product)"
      [typeval]="'delete_outline'"
      type="icon"
      [theme]="'borderless-danger'"
    >
    </dadyin-button>
  </div>
  <!-- <dadyin-button
    matTooltip="Customize this product"
    class="customisable bg-secondary b-light"
    *ngIf="product?.productDetails?.isCustomizable"
    (clicked)="viewCustomisedDetail(product, this.i, true)"
    type="image"
    [typeval]="
      isMyProductValue
        ? 'assets/img/icons/myproduct.svg'
        : 'assets/img/icons/Exclusive Product-Blue.png'
    "
    [label]="isMyProductValue ? 'My Product' : 'Customizable'"
    [theme]="'borderless-primary'"
  >
  </dadyin-button> -->

  <div class="attribute tabular-sheet customisable">
    <div class="attribute-input">
      <div class="toggle bg-white flex-column">
        <button
          matTooltip="Customise Product"
          class="w-100"
          [disabled]="
            isSelfProduct(product?.productDetails) ||
            !product?.productDetails?.isCustomizable ||
            product?.isCustomized
          "
          (click)="customise(product.quantity, true)"
          [ngClass]="{
            active: product?.isCustomized
          }"
        >
          <!-- <img
            width="20px"
            [src]="
              isMyProductValue
                ? 'assets/img/icons/myproduct.svg'
                : 'assets/img/icons/Exclusive Product-Blue.png'
            "
            alt=""
          /> -->
          {{ isMyProductValue ? "My Product" : "Custom" }}
        </button>
        <button
          matTooltip="View Generic Product"
          class="w-100"
          [disabled]="isNoGenericPurchase(product) || !product?.isCustomized"
          (click)="customise(product.quantity, false)"
          [ngClass]="{
            active: !product?.isCustomized
          }"
        >
          Generic
        </button>
      </div>
    </div>
  </div>

  <div class="share-button">
    <dadyin-button
      type="icon"
      typeval="share_alt"
      class="w-100"
      style="text-wrap: nowrap"
      (clicked)="share(product)"
      [theme]="'borderless-primary'"
    >
    </dadyin-button>
  </div>
</div>

<ng-template #customtooltip>
  <div class="row customtooltip">
    <div class="col-md-12">
      <label class="tooltip-title">
        <b> Owner: </b>
      </label>
      <span>{{ product?.productDetails?.auditBusinessAccountName }}</span>
    </div>
    <div class="col-12" *ngIf="getOwner(product?.productDetails?.audit) == 'S'">
      <label> <b> Created Date: </b></label
      ><span>{{
        product?.productDetails?.audit.createdDate | date : "MM-dd-yyyy"
      }}</span>
      <br />
      <label> <b>Last Modified Name: </b></label
      ><span>{{ product?.productDetails?.audit.lastModifiedByName }}</span
      ><br />
      <label> <b> Last Modified Date: </b></label
      ><span>{{
        product?.productDetails?.audit.lastModifiedDate | date : "MM-dd-yyyy"
      }}</span>
    </div>
  </div>
</ng-template>
